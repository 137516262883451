import { OrderCommentModel, OrderCommentModelMapper } from "./OrderCommentModel";
import { CustomerModelMapper, CustomerModel } from "../CustomerModel";
import { DeliveryModelMapper, DeliveryModel } from "../DeliveryModel";
import { ProductModel, ProductModelMapper } from "../product/ProductModel";
import { OrderPoNrModel } from "./OrderPoNrModel";
import { OrderStatusModel, OrderStatusModelMapper } from "./OrderStatusModel";
import { LoginModel, LoginModelMapper } from "../login/LoginModel";
import { ModelMapper } from "../ModelMapper";
import { OrderBundleModel } from "../project/OrderBundleModel";

export interface OrderModel {
  id: string;
  project_id: string;
  user_id: string;
  status: OrderStatusModel;
  deliveryDate: string;
  reference: string;
  requisition: string;
  caseNumber: string;
  sapNumber: string;
  poNumbers: OrderPoNrModel[];
  comment: OrderCommentModel;
  invoice: boolean;
  invoiceHold: boolean;
  orderConfirm: boolean;
  orderOnHold: boolean;
  customerUpdate: boolean;
  complete: boolean;
  seller: string;
  customer: CustomerModel;
  delivery: DeliveryModel;
  products: ProductModel[];
  user: LoginModel;
  information: { [infoField: string]: string };
  bundles: OrderBundleModel[];
  tracking: string;
  date_registered: string;
  date_updated: string;
  deletable: boolean;
  props: {
    type: string;
  };
}

export const OrderModelMapper: ModelMapper<OrderModel> = {
  createEmptyObject: () => {
    return {
      id: "",
      project_id: "",
      user_id: "",
      status: OrderStatusModelMapper.createEmptyObject(),
      deliveryDate: "",
      reference: "",
      requisition: "",
      caseNumber: "",
      sapNumber: "",
      poNumbers: [],
      comment: OrderCommentModelMapper.createEmptyObject(),
      invoice: false,
      invoiceHold: false,
      orderConfirm: false,
      orderOnHold: false,
      customerUpdate: false,
      complete: false,
      seller: "",
      customer: CustomerModelMapper.createEmptyObject(),
      delivery: DeliveryModelMapper.createEmptyObject(),
      products: [],
      user: LoginModelMapper.createEmptyObject(),
      information: {},
      bundles: [],
      tracking: "",
      date_registered: "",
      date_updated: "",
      deletable: false,
      props: {
        type: "",
      },
    };
  },

  fromBackendObject: (backendObject: any): OrderModel => {
    const order = OrderModelMapper.createEmptyObject();

    order.id = backendObject.id || "";
    order.project_id = backendObject.project_id || "";
    order.user_id = backendObject.user_id || "";
    order.status = OrderStatusModelMapper.fromBackendObject(backendObject.status);
    order.deliveryDate = backendObject.order?.deliveryDate?.split(" ")[0];
    order.reference = backendObject.order?.reference || "";
    order.requisition = backendObject.order?.requisition || "";
    order.caseNumber = backendObject.order?.casenr || "";
    order.sapNumber = backendObject.order?.sapnr || "";
    order.poNumbers = backendObject.order?.ponr || [];
    order.comment = OrderCommentModelMapper.fromBackendObject(backendObject.comment);
    order.invoice = backendObject.order?.invoice ? parseInt(backendObject.order.invoice) === 1 : false;
    order.invoiceHold = backendObject.order?.invoiceHold ? parseInt(backendObject.order.invoiceHold) === 1 : false;
    order.orderConfirm = backendObject.order?.confirm ? parseInt(backendObject.order.confirm) === 1 : false;
    order.orderOnHold = backendObject.order?.hold ? parseInt(backendObject.order.hold) === 1 : false;
    order.customerUpdate = backendObject.order?.customerupd ? parseInt(backendObject.order.customerupd) === 1 : false;
    order.complete = backendObject.order?.complete ? parseInt(backendObject.order.complete) === 1 : false;
    order.seller = backendObject.order?.seller || "";
    order.customer = CustomerModelMapper.fromBackendObject(backendObject.customer);
    order.delivery = DeliveryModelMapper.fromBackendObject(backendObject.delivery);
    order.products = backendObject.products
      ? backendObject.products.map((item: any) => {
          const product = ProductModelMapper.fromBackendObject(item);
          return product;
        })
      : [];
    order.user = LoginModelMapper.fromBackendObject(backendObject.user);
    order.information = backendObject.information;
    order.tracking = backendObject.order?.tracking;
    order.date_registered = backendObject.date_registered?.split(" ")[0] || "";
    order.date_updated = backendObject.date_updated?.split(" ")[0] || "";
    order.deletable = backendObject.deletable;
    order.props = backendObject.order;

    return order;
  },

  toBackendObject: (order: OrderModel) => {
    return {
      id: order.id,
      project_id: order.project_id,
      user_id: order.user_id,
      customer: CustomerModelMapper.toBackendObject(order.customer),
      order: {
        deliveryDate: order.deliveryDate,
        invoice: order.invoice ? "1" : "0",
        invoiceHold: order.invoiceHold ? "1" : "0",
        reference: order.reference,
        requisition: order.requisition,
        casenr: order.caseNumber,
        sapnr: order.sapNumber,
        ponr: order.poNumbers,
        confirm: order.orderConfirm ? "1" : "0",
        hold: order.orderOnHold ? "1" : "0",
        customerupd: order.customerUpdate ? "1" : "0",
        complete: order.complete ? "1" : "0",
        seller: order.seller,
        information: order.information,
      },
      comment: OrderCommentModelMapper.toBackendObject(order.comment),
      delivery: DeliveryModelMapper.toBackendObject(order.delivery),
      products: order.products.map((item: ProductModel) => {
        const backendProduct = ProductModelMapper.toBackendObject(item);
        return backendProduct;
      }),
      user: LoginModelMapper.toBackendObject(order.user),
      information: order.information,
    };
  },
};
