import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppLayout } from "./AppLayout";
import { CreateOrderForm, EmailListForm } from "./components/forms";
import { ReactNode } from "react";
import { OrderListForm } from "./components/forms/OrderListForm";
import { ProjectWizard } from "./components/forms/project/ProjectWizard";
import { OrderList } from "./components/forms/project/OrderList";
import { OrderWizard } from "./components/forms/project/OrderWizard";
import { ProjectListForm } from "./components/forms/ProjectListForm";
import { ENV } from "./env";
import { DashboardModule, ExModule, FlowsModule, FormsModule, SklModule, ToolsModule, UsersModule } from "./modules";
import { usePermissionChecker } from "./modules/common/hooks/usePermissionChecker";
import { SidebarItem } from "./components/Sidebar";
import { ModuleContainer } from "./modules/common/components";
import { FlowPage } from "./modules/flows/FlowPage";
import { ToolPage } from "./modules/tools/ToolPage";

interface AppModuleModel {
  label?: string;
  route: string;
  name: string;
  icon?: string;
  form: ReactNode;
  disabled?: boolean;
}

const AppModules: AppModuleModel[] = [
  { label: "Dashboard", route: "/", name: "", icon: "/assets/images/sidebar/home.svg", form: <DashboardModule /> },
  { label: "Kredittsalg", route: "/kredittsalg", name: "hw", icon: "/assets/images/sidebar/new-order.svg", form: <CreateOrderForm /> },
  { label: "Digital Fieldworker", route: "/ex", name: "ex", icon: "/assets/images/sidebar/ex.svg", form: <ExModule /> },
  { label: "Ordrer", route: "/ordrer", name: "orders", icon: "/assets/images/sidebar/order-list.svg", form: <OrderListForm /> },
  { label: "Prosjekter", route: "/prosjekter", name: "projects", icon: "/assets/images/sidebar/project.svg", form: <ProjectListForm /> },
  { route: "/prosjekter/:id", name: "", form: <ProjectWizard /> },
  { route: "/prosjekter/:id/ordrer", name: "", form: <OrderList /> },
  { route: "/prosjekter/:projectId/ordrer/:orderId", name: "", form: <OrderWizard /> },
  { label: "Brukere", route: "/brukere", name: "users", icon: "/assets/images/sidebar/person.svg", form: <UsersModule /> },
  { label: "Meldinger", route: "/meldinger", name: "emails", icon: "/assets/images/sidebar/mail.svg", form: <EmailListForm /> },
  { label: "SKL-Teams", route: "/skl", name: "skl", icon: "/assets/images/sidebar/skl-teams.svg", form: <SklModule /> },
  { label: "Flows", route: "/flows", name: "flows", icon: "/assets/images/sidebar/flows.svg", form: <FlowsModule /> },
  { route: "/flows/:id", name: "flows", form: <FlowPage /> },
  { label: "Forms", route: "/forms", name: "forms", icon: "/assets/images/sidebar/forms.svg", form: <FormsModule />, disabled: true },
  { label: "Verktøy", route: "/tools", name: "tools", icon: "/assets/images/sidebar/tools.svg", form: <ToolsModule /> },
  { route: "/tools/:id", name: "", form: <ToolPage />, disabled: ENV === "prod" },
  // { route: "/export/ordrer/:id", name: "", form: <HwOrderExport /> },
];

export const AppRouter = () => {
  const permissionChecker = usePermissionChecker();

  const sidebarItems: SidebarItem[] = AppModules.filter((module) => !module.disabled && permissionChecker.isAccessAllowed(1, module.name) && module.label && module.icon).map((module) => ({
    label: module.label || "",
    icon: module.icon || "",
    route: module.route,
  }));

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout sidebarItems={sidebarItems} />}>
          {AppModules.map((module) => (
            <Route
              key={module.route}
              path={module.route}
              element={
                module.disabled || !permissionChecker.isAccessAllowed(1, module.name) ? (
                  <div className="flex h-full w-full">
                    <h2 className="m-auto text-2xl">Ingen tilgang</h2>
                  </div>
                ) : (
                  <ModuleContainer name={module.name}>{module.form}</ModuleContainer>
                )
              }
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
