import { useContext, useEffect, useState } from "react";
import OrderModel from "../../models/OrderModel";
import { OrdersManagementContext } from "../../contexts/OrdersManagementContext";
import { Loading } from "../common/Loading";
import EditOrderDialog from "../dialogs/EditOrderDialog";
import { CustomDataTable } from "../common/DataTable";
import Button from "../common/Button";
import AppContext from "../../contexts/AppContext";
import { updateOrder, changeOrderStatus } from "../../backend";
import { UserManagementContext } from "../../contexts/UserManagementContext";
import { ExportButton } from "../../export/components/ExportButton";
import { OrderSchema } from "../../export/schemas/OrderSchema";
import { SearchField, ToolButton } from "../../modules/common/elements";
import { HwOrderExportButton } from "../../modules/hw/HwOrderExportButton";

const StatusField = (order: OrderModel) => {
  const appContext = useContext(AppContext);
  const ordersManagement = useContext(OrdersManagementContext);

  const [editing, setEditing] = useState(false);
  const [operation, setOperation] = useState(order.status.operations[0]);

  return (
    <div className="flex gap-4">
      {editing ? (
        <select
          className="w-32 rounded-md text-black"
          value={operation}
          onChange={(e) => {
            e.preventDefault();
            setOperation(e.target.value);
          }}
        >
          {order.status.operations.map((o) => (
            <option value={o}>{o}</option>
          ))}
        </select>
      ) : (
        <span className="w-32">{order.status.value}</span>
      )}
      <div className="flex gap-1">
        <Button
          onClick={async () => {
            if (editing) {
              // appContext.setProcessing(true);
              await changeOrderStatus(order.id, operation, appContext.login?.uid);
              await ordersManagement.refreshOrders();
              // appContext.setProcessing(false);
            }
            setEditing(!editing);
          }}
        >
          {editing ? "Save" : "Change"}
        </Button>
        {editing && (
          <Button
            onClick={() => {
              setEditing(false);
            }}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

const NrField = (order: OrderModel, fieldName: string) => {
  const appContext = useContext(AppContext);
  const ordersManagement = useContext(OrdersManagementContext);

  const [editing, setEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState((order as any)[fieldName]);

  return (
    <div className="flex gap-4">
      {editing ? (
        <input
          className="w-32 rounded-md text-black px-1"
          type="text"
          value={fieldValue}
          onChange={(e) => {
            setFieldValue(e.target.value);
          }}
        />
      ) : (
        <span className="w-32">{fieldValue}</span>
      )}
      <div className="flex gap-1">
        <Button
          onClick={async () => {
            if (editing) {
              // appContext.setProcessing(true);
              await updateOrder({ ...order, [fieldName]: fieldValue }, appContext.login?.uid);
              await ordersManagement.refreshOrders();
              // appContext.setProcessing(false);
            }
            setEditing(!editing);
          }}
        >
          {editing ? "Save" : "Change"}
        </Button>
        {editing && (
          <Button
            onClick={() => {
              setEditing(false);
            }}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

const expandedRowFields: {
  label: string;
  getValue: (order: OrderModel) => JSX.Element | string;
}[] = [
  {
    label: "Order ID",
    getValue: (order: OrderModel) => order.id,
  },
  {
    label: "Status",
    getValue: (order: OrderModel) => StatusField(order),
  },
  {
    label: "CaseNr",
    getValue: (order: OrderModel) => NrField(order, "caseNumber"),
  },
  {
    label: "SapNr",
    getValue: (order: OrderModel) => NrField(order, "sapNumber"),
  },
  {
    label: "PoNr",
    getValue: (order: OrderModel) => NrField(order, "poNumber"),
  },
  {
    label: "Sporingsnummer",
    getValue: (order: OrderModel) => NrField(order, "tracking"),
  },
  {
    label: "Kundereferanse",
    getValue: (order: OrderModel) => order.reference,
  },
  {
    label: "Kunde",
    getValue: (order: OrderModel) => `(${order.customer.orgnr}) ${order.customer.name1}, ${order.customer.address1}, ${order.customer.zipcode} ${order.customer.city}`,
  },
];

const ExpandedRowComponent: (data: any) => JSX.Element = ({ data }: { data: any }) => {
  const order = data as OrderModel;
  console.log("ORDER", order)
  return (
    <div className="flex flex-1 m-2 text-md p-4 border-double border-b-4 border-[#162521]">
      <div className="flex flex-1 flex-col gap-2 ml-8">
        {expandedRowFields.map((field) => (
          <div key={field.label} className="flex gap-4">
            <span className="w-48 font-bold">{field.label}</span>
            <span>{field.getValue(order)}</span>
          </div>
        ))}
      </div>
      <div className="flex flex-1 flex-col gap-2 ml-8">
        <div className="ml-auto">
          <HwOrderExportButton orderId={order.id} />
        </div>
      </div>
    </div>
  );
};

export const OrderListForm = () => {
  const appContext = useContext(AppContext);
  const userManagement = useContext(UserManagementContext);
  const ordersManagement = useContext(OrdersManagementContext);
  const [filters, setFilters] = useState<{ userOrdersOnly: boolean; searchText: string }>({ userOrdersOnly: false, searchText: "" });

  const columns = [
    {
      name: "Id",
      selector: (row: OrderModel) => parseInt(row.id),
      sortable: true,
      width: "6rem",
    },
    {
      name: "Type",
      selector: (row: OrderModel) => row.props?.type || "",
      sortable: true,
      width: "6rem",
    },
    {
      name: "Status",
      // selector: (row: OrderModel) => row.status.value,
      cell: (row: OrderModel) =>
        row.status.value === "draft" ? (
          <p>
            <span>{row.status.value}</span>
            <button
              className="text-xs font-bold px-2 py-1 rounded-md ibutton cursor-pointer ml-1"
              onClick={async () => {
                await changeOrderStatus(row.id, "ready", appContext.login?.uid);
                await ordersManagement.refreshOrders();
              }}
              id={row.id}
            >
              Klar for SAP
            </button>
          </p>
        ) : (
          <p className="flex">
            <span>{row.status.value}</span>
            {/* <span style={["end", "created"].includes(row.status.value) ? { color: "yellow"} : row.status.value === "attention" ? {color: "red"} : {}}>{row.status.value}</span> */}
            {["check", "manual"].includes(row.status.value) && <img className="mr-1" src="/assets/images/states/warning.svg" alt="warning" />}
            {row.status.value === "attention" && <img className="ml-1" src="/assets/images/states/error.svg" alt="error" />}
          </p>
        ),
      sortable: true,
      width: "10rem",
    },
    {
      name: "CaseNr",
      selector: (row: OrderModel) => row.caseNumber,
      width: "10rem",
    },
    {
      name: "SapNr",
      selector: (row: OrderModel) => row.sapNumber,
      width: "10rem",
    },
    {
      name: "PoNr",
      selector: (row: OrderModel) => {
        const ponrString = row.poNumbers.map?.((item) => item.ponr).join(", ");
        return ponrString;
      },
      width: "10rem",
    },
    {
      name: "Sporingsnummer",
      selector: (row: OrderModel) => row.tracking,
      width: "12rem",
    },
    // {
    //   name: "Kundereferanse",
    //   selector: (row: OrderModel) => row.reference,
    // },
    // {
    //   name: "Orgnr",
    //   selector: (row: OrderModel) => row.customer.orgnr,
    // },
    {
      name: "Kunde Navn",
      selector: (row: OrderModel) => row.customer.name1,
      sortable: true,
    },
    {
      name: "Selger",
      selector: (row: OrderModel) => {
        const user = userManagement.getUser(row.seller);
        return user.name || user.email;
      },
      sortable: true,
      width: "12rem",
    },
    {
      name: "Registreringsdato",
      selector: (row: OrderModel) => row.date_registered,
      sortable: true,
      width: "12rem",
    },
  ];

  const [editingOrder, setEditingOrder] = useState<OrderModel | null>(null);
  // const [exportSchema, setExportSchema] = useState<Schema<OrderModel>>(OrderSchema);

  useEffect(() => {
    if (!ordersManagement.state.status) {
      ordersManagement.refreshOrders();
    }
  }, []);

  const getFilteredOrders = (): OrderModel[] => {
    let filteredOrders = ordersManagement.allOrders;
    if (filters.userOrdersOnly) {
      filteredOrders = filteredOrders.filter((order) => order.user_id === appContext.login?.id);
    }
    if (filters.searchText) {
      filteredOrders = filteredOrders.filter((order) =>
        `${order.id} ${order.status.value} ${order.caseNumber} ${order.sapNumber} ${order.tracking} ${order.customer.name1} ${userManagement.getUser(order.seller).name} ${order.date_registered}`.toLowerCase().includes(filters.searchText)
      );
    }
    return filteredOrders;
  };

  return (
    <div className="flex w-full h-full">
      <div className="flex w-full relative">
        {ordersManagement.state.status === "processing" && (
          <div className="absolute flex z-40 w-full h-full rounded-xl">
            <Loading />
          </div>
        )}
        {ordersManagement.state.status === "error" && <div className="m-auto">{ordersManagement.state.message}</div>}
        {editingOrder && (
          <EditOrderDialog
            order={editingOrder}
            closeHandler={(refresh) => {
              setEditingOrder(null);
              if(refresh) {
                ordersManagement.refreshOrders();
              }
            }}
          />
        )}
        {!editingOrder && (
          <div className="flex flex-col p-4 w-full">
            <div className="flex mb-2 gap-2">
              <SearchField placeholder="Filtrer ordrer..." onSearch={(searchText) => setFilters({ ...filters, searchText: searchText })} />
              <button className={`${filters.userOrdersOnly ? "tp-flex-button-confirm" : "tp-flex-button-cancel"}`} onClick={() => setFilters({ ...filters, userOrdersOnly: !filters.userOrdersOnly })}>
                Vis mine ordre
              </button>
              <ExportButton<OrderModel>
                defaultSchema={OrderSchema}
                onGetExportedItems={() => {
                  return ordersManagement.allOrders;
                }}
              />
              <ToolButton className="ml-auto" image="/assets/images/refresh.svg" onClick={ordersManagement.refreshOrders} />
            </div>
            <div className="w-full">
              <CustomDataTable
                columns={columns}
                data={getFilteredOrders()}
                expandedRow={ExpandedRowComponent}
                onMoreClicked={(row: OrderModel) => {
                  setEditingOrder(row);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
